@use 'src/styles/mixins' as mix;
@use 'src/styles/variables' as var;

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  @include mix.vp-1024 {
    justify-content: flex-start;
    padding: 60px 0;
  }
}
