@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.input {
  outline: none;
  border: none;
  @include mix.text(18, 500, 18);
  color: var.$black;
  width: 100%;
  height: 100%;
  background-color: transparent;
  resize: none;
  padding: 16px;

  &::placeholder {
    @include mix.text(18, 500, 18);
    color: var.$black-50;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

.container {
  position: relative;
  width: 100%;
  border-radius: 10px;
  border: 1px solid var.$gray;
  background-color: var.$white;
  transition: all 0.3s ease;
}

.errorTag {
  color: var.$danger;
  @include mix.text(12, 400, 16);
}

.focused {
  border: 1px solid var.$black;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.count {
  display: flex;
  justify-content: flex-end;
  @include mix.text(12, 400, 16);
  color: var.$black-80;
}
