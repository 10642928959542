$monasans: MonaSans, sans-serif;
$manrope: Manrope, sans-serif;

$primary: #40a5e8;
$danger: #ff4d4d;
$warning: #ffa500;
$success: #4dc94d;
$purple: #de69f3;
$blue: #7187f7;
$orange: #f38a4e;

$white: #ffffff;
$dark: #242424;
$black: #191919;
$black-90: #19191990;
$black-80: #19191980;
$black-50: #19191950;
$black-10: #1919191a;
$gray: #eaeaea;
$gray-light: #f5f5f5;
$gray-dark: #c1c1c1;
$gray-black: #515151;
