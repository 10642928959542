@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.wrapper {
  z-index: 0;
  margin-bottom: 150px;

  @include mix.vp-1024 {
    margin-bottom: 80px;
  }
}

.blocks {
  width: 100%;
  margin: 0 auto;
  display: flex;
  gap: 20px;
  max-width: 1024px;

  @include mix.vp-1024 {
    display: none;
  }
}

.slider {
  display: none !important;

  @include mix.vp-1024 {
    display: flex !important;
  }
}

.block {
  @include mix.block;
  padding: 32px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  position: relative;
  justify-content: space-between;
  height: 450px;

  @include mix.vp-1024 {
    height: 380px;
  }

  @include mix.vp-767 {
    height: 420px;
  }

  @media (max-width: 500px) {
    height: 480px;
  }

  .priceWrapper {
    position: relative;
    height: 40px;
    overflow: hidden;
    width: 100%;
    border-bottom: 1px solid var.$gray-dark;
    min-height: 40px;
  }

  .price,
  .priceAmount {
    position: absolute;
    left: 0;
    width: 100%;
    transition: transform 0.3s ease-in-out;
    @include mix.text(32, 700, 32);
    text-align: center;
    height: 40px;
  }

  .price {
    transform: translateY(0);
  }

  .priceAmount {
    transform: translateY(100%);
  }

  @media (hover: hover) and (min-width: 1025px) {
    &:hover {
      .price {
        transform: translateY(-100%);
      }

      .priceAmount {
        transform: translateY(0);
      }
    }
  }

  &.touched {
    .price {
      transform: translateY(-100%);
    }

    .priceAmount {
      transform: translateY(0);
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.points {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.point {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: var.$gray-dark;
  border-radius: 50%;
  min-width: 10px;
  min-height: 10px;
  max-width: 10px;
  max-height: 10px;

  &.redDot {
    background-color: var.$danger;
  }
}

button.button {
  @include mix.vp-1024 {
    width: 200px;
  }
}

.hint {
  display: none;
  @include mix.text(14, 400, 14);
  color: var.$gray-dark;
  text-align: center;
  margin: 0 auto;

  @include mix.vp-1024 {
    display: block;
  }
}

.priceContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}
