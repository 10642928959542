@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 64px;
  margin-bottom: 300px;
  z-index: 5;

  @include mix.vp-1024 {
    margin-bottom: 150px;
  }
}

.heading {
  display: flex;
  flex-direction: column;
}

.title {
  @include mix.text(56, 800, 60);
  text-align: center;
  color: var.$dark;
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 16px 40px;

  @include mix.vp-1024 {
    display: block;
    @include mix.text(48, 800, 52);
    padding: 0 16px 20px;
  }

  @include mix.vp-767 {
    @include mix.text(32, 800, 36);
  }

  @include mix.vp-440 {
    @include mix.text(24, 800, 28);
  }
}

div.logo {
  margin: 0 auto;
  margin-bottom: 60px;
  height: 120px;
  cursor: default;

  @include mix.vp-1024 {
    margin-bottom: 40px;
    height: 80px;
  }
}

img.logoIcon {
  height: 120px;
  width: 120px;
  cursor: default;

  @include mix.vp-1024 {
    height: 80px;
    width: 80px;
  }
}

.logoText {
  @include mix.text(100, 800, 100);

  @include mix.vp-1024 {
    @include mix.text(48, 800, 48);
  }
}

.desc {
  text-align: center;
  @include mix.text(24, 300, 28);
  color: var.$black-90;
  margin: 0 auto;
  max-width: 630px;
  padding: 0 16px;

  @include mix.vp-767 {
    @include mix.text(16, 300, 20);
  }
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: 360px;
  margin: 0 auto;

  @include mix.vp-1024 {
    flex-direction: column-reverse;
    padding: 0 16px;
    gap: 12px;
  }
}
